/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LinkedinOutlined, TwitterOutlined } from '@ant-design/icons'

const Bio = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            linkedin
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <>
      <p>
        &copy;{new Date().getFullYear()} <strong>{author.name}</strong>{' '}
        {author?.summary || null}
      </p>
      <a href={`https://twitter.com/${social?.twitter || ``}`}>
        <TwitterOutlined />
      </a>
      <a href={`https://linkedin.com/in/${social?.linkedin || ``}`}>
        <LinkedinOutlined />
      </a>
    </>
  )
}

export default Bio
