import React from 'react'
import cx from 'classnames'
import { Breadcrumb, Layout as AntdLayout, Menu } from 'antd'
import { Link } from 'gatsby'
import Bio from './bio'
import { Pages, Site } from '../types'

const { Header: AntdHeader, Content, Footer } = AntdLayout

const Header: React.FC<{ site: Site; pages: Pages }> = ({ site, pages }) => {
  return (
    <AntdHeader className="blog-header">
      <div className="blog-header-inner">
        <div className="logo">
          <Link to="/">{site.siteMetadata?.title}</Link>
        </div>
        <div className="description">{site.siteMetadata?.description}</div>
      </div>
      <Menu theme="dark" className="blog-menu" mode="horizontal">
        {pages.nodes.map(node => {
          const slug = node.childMarkdownRemark.fields.slug
          return (
            <Menu.Item key={slug}>
              <Link to={slug}>
                {node.childMarkdownRemark.frontmatter.title}
              </Link>
            </Menu.Item>
          )
        })}
      </Menu>
    </AntdHeader>
  )
}

interface NavItem {
  title: string
  href: string
}

const Layout: React.FC<{
  location: Location
  title?: string
  site: Site
  pages: Pages
  nav?: NavItem[]
}> = ({ children, title, site, pages, nav = [] }) => {
  nav.unshift({
    href: '/',
    title: 'Home'
  })
  return (
    <AntdLayout className="layout">
      <Header site={site} pages={pages} />
      <Content className="content-wrapper">
        <Breadcrumb className={cx({ invisible: nav.length === 1 })}>
          {nav.map((item, index) => (
            <Breadcrumb.Item key={item.href}>
              {(index < nav.length - 1 && (
                <Link to={item.href}>{item.title}</Link>
              )) ||
                item.title}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
        {title && <h2 className="page-title">{title}</h2>}
        <div className="site-layout-content">{children}</div>
      </Content>
      <Footer className="blog-footer">
        <Bio />
      </Footer>
    </AntdLayout>
  )
}

// const Layout: React.FC<{ location: Location; title: string }> = ({
//   location,
//   title,
//   children
// }) => {
//   const rootPath = `${__PATH_PREFIX__}/`
//   const isRootPath = location.pathname === rootPath
//   let header

//   if (isRootPath) {
//     header = (
//       <h1 className="main-heading">
//         <Link to="/">{title}</Link>
//       </h1>
//     )
//   } else {
//     header = (
//       <Link className="header-link-home" to="/">
//         {title}
//       </Link>
//     )
//   }

//   return (
//     <div className="global-wrapper" data-is-root-path={isRootPath}>
//       <header className="global-header">{header}</header>
//       <main>{children}</main>
//       <footer>
//         © {new Date().getFullYear()}, Built with
//         {` `}
//         <a href="https://www.gatsbyjs.com">Gatsby</a>
//       </footer>
//     </div>
//   )
// }

export default Layout
